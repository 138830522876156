import React, { Fragment } from 'react'
import { PHONE_TYPES } from 'src/models/Phone'

import ContactValidation from 'src/base/ContactValidation'

const LINE_REGEX = /(\d{1,2})(\d{1,4})(\d{1,4})/
const MOBILE_REGEX = /(\d{1,2})(\d{1,5})(\d{1,4})/

export default function Builder(params) {
  const { intl, messages, person } = params
  const { phones = [], emails = [], substatus } = person

  const phoneFields = mapPhonePriority(phones).reduce(createPhoneField, [params])
  const [email] = emails

  return {
    id: 'profile-contact',
    title: intl.formatMessage(messages.box_contact_title),
    wrapper: 'default',
    content: 'list',
    fields: [
      ...phoneFields,
      {
        label: intl.formatMessage(messages.box_contact_label_email),
        field: (
          <Fragment>
            {email && email.email || '-'} &nbsp;
            <ContactValidation email={email} substatus={substatus} />
          </Fragment>
        )
      }
    ]
  }
}

const mapPhonePriority = (phones) => {
  if (!phones || phones.length === 0) return []

  // Ordenar primeiro pelos campos de updatedAt (se existir)
  const sortedPhones = phones.sort((a, b) => {
    const dateA = new Date(a.updatedAt || 0).getTime()
    const dateB = new Date(b.updatedAt || 0).getTime()
    return dateB - dateA
  })

  // Priorizar os telefones do tipo WHATSAPP ou MOBILE
  const prioritizedPhones = sortedPhones.sort((a, b) => {
    const isAPriority = a.type === PHONE_TYPES.WHATSAPP || a.type === PHONE_TYPES.MOBILE
    const isBPriority = b.type === PHONE_TYPES.WHATSAPP || b.type === PHONE_TYPES.MOBILE

    if (isAPriority && !isBPriority) return -1
    if (!isAPriority && isBPriority) return 1
    return 0
  })

  // Retornar os dois primeiros telefones priorizados
  return prioritizedPhones.slice(0, 2)
}

export const phoneEnum = {
  [PHONE_TYPES.LINE]: 'box_contact_label_phone',
  [PHONE_TYPES.MOBILE]: 'box_contact_label_mobile',
  [PHONE_TYPES.WHATSAPP]: 'box_contact_label_mobile',
  [PHONE_TYPES.OTHERS]: 'box_contact_label_other',
  [PHONE_TYPES.NOT_INFORMED]: 'box_contact_label_other',
}

export function createPhoneField(accum, phoneObj, index, array) {
  const { phoneNumber = '', areaCode = '', type } = phoneObj
  const [{ intl, messages }] = accum

  const lastItem = index === array.length - 1

  if (lastItem) accum.shift()

  if (!phoneEnum[type]) return accum

  const FORMAT_DIGITS = type === PHONE_TYPES.LINE ? LINE_REGEX : MOBILE_REGEX
  const formatedPhone = `${areaCode}${phoneNumber}`.replace(FORMAT_DIGITS, '($1) $2-$3')

  const phone = {
    label: intl.formatMessage(messages[phoneEnum[type]]),
    field: (
      <Fragment>
        {formatedPhone}
      </Fragment>
    )
  }

  return [...accum, phone]
}
